const CATEGORY = {
    "EVENT": "1",
    "KONSER": "2",
    "OLAHRAGA": "3",
    "WORKSHOP": "4",
    "HEALING": "5",
    "RETAIL": "6",
    "KOMEDI": "7",
    "ATRAKSI": "8",
    "TAMAN_HIBURAN": "9",
    "WATER_PARKS": "10",
    "KEBUN_BINATANG_ALAM": "11",
    "TEMPAT BERSEJARAH": "12",
    "HIBURAN LAINNYA": "13",
    "MAKANAN": "14",
    "PERMAINAN": "15",
    "KARAOKE": "16",
    "PENGEMBANGAN_DIRI": "17",
    "SEMINAR": "18",
    "RACE": "19",
}

const GROUP_CATEGORY = {
    EVENT: [CATEGORY.EVENT, CATEGORY.KOMEDI, CATEGORY.OLAHRAGA, CATEGORY.KONSER, CATEGORY.RACE],
    NON_EVENT: [CATEGORY.HEALING, CATEGORY.ATRAKSI, CATEGORY.TAMAN_HIBURAN, CATEGORY.KEBUN_BINATANG, CATEGORY.TEMPAT_BERSEJARAH, CATEGORY.WATER_PARKS]
}

export { CATEGORY, GROUP_CATEGORY }