import aboutUs from "./about-us"
import contactUs from "./contact-us"
import footer from "./footer"
import helpCenter from "./help-center"
import home from "./home"
import syaratKetentuan from "./syarat-ketentuan"

const id = {
    home: home,
    footer: footer,
    'contact-us': contactUs,
    'about-us': aboutUs,
    'help-center': helpCenter,
    "toc": syaratKetentuan
}

export default id