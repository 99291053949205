import { createI18n } from 'vue-i18n'
import id from './id/id'
import en from './en/en'

const i18n = createI18n({
    locale: 'id',
    fallbackLocale: 'id',
    messages: {
        id: id,
        en: en
    }
})

export default i18n