export default {
    ticket: {
        title: "Ragam Tiket",
        content: {
            concert: {
                title: "Konser Musik",
                value: "music-concert"
            },
            attraction: {
                title: "Atraksi",
                value: "attraction"
            },
            healing: {
                title: "Healing",
                value: "healing-sejenak"
            },
            race: {
                title: "Race",
                value: "race"
            },
        }
    },
    'e-tix': {
        title: "e-tix",
        content: {
            'contact-us': {
                title: "Kontak Kami"
            },
            'pricing': {
                title: "Biaya"
            },
            'about': {
                title: "Tentang Kami"
            },
            "help": {
                title: "Pusat Bantuan"
            }
        }
    },
    howto: {
        title: "How to",
        content: {
            'buy-ticket': {
                title: "Cara membeli tiket",
                value: "buy-ticket"
            },
            'sell-ticket': {
                title: "Cara menjual tiket",
                value: "sell-ticket"
            },
            'subscription-kiosk': {
                title: "Cara berlangganan kiosk",
                value: "subscription-kiosk"
            },
        }
    },
    follow: {
        title: "Ikuti Kami"
    },
    quotes: [
        "Beragam Jenis Tiket",
        "Cepat, Mudah dan Aman",
        "Hanya di"
    ],
    copyright: "© 2023 e-tix PT Softorb Technology Indonesia. Syarat dan Ketentuan Berlaku."
}
