export default {
    title: "Kebijakan Privasi e-tix",
    'update-info': "Terakhir diperbarui {date}",
    descriptions: [
        "Selamat datang di platform e-tix. Kami yakin bahwa Anda sangat memperhatikan keamanan data pribadi Anda. Maka dari itu kami berkomitmen untuk melindungi dan menghormati privasi Anda, serta memberikan informasi bagaimana cara menggunakan, dan melindungi data pribadi Anda.",
        "Kebijakan Privasi ini menjelaskan bagaimana cara kami mengumpulkan, memproses, dan membagikan data pribadi pengguna. Kebijakan Privasi ini berlaku untuk setiap Penyedia  Tiket dan setiap Pelanggan Tiket. Kebijakan Privasi diperlukan untuk menjaga keamanan data pribadi Anda, mohon untuk dibaca seluruh ketentuan dengan teliti dan seksama."
    ],
    "collecting-information": {
        title: "Informasi yang kami kumpulkan",
        description: "Kami akan mengumpulkan informasi mengenai komputer atau media yang Anda gunakan untuk mengakses platform kami. Informasi yang kami kumpulkan mencakup, tetapi tidak terbatas pada:",
        explanations: [
            "Informasi pribadi seperti alamat e-mail dan nomor telepon.",
            "Informasi terkait masuk akun, kata sandi, dan detail masuk Anda.",
            "Informasi pembayaran seperti rincian kartu kredit atau debit Anda, atau informasi pembayaran lainnya.",
            "Informasi transaksi yang terjadi.",
            "Informasi teknis seperti alamat IP Anda, dan informasi perangkat yang Anda gunakan.",
            "Informasi lainnya yang Anda bagikan dengan kami melalui formulir atau komunikasi lainnya.",
        ]
    },
    "use-of-information": {
        title: "Penggunaan informasi Anda",
        description: "Kami menggunakan informasi yang kami kumpulkan untuk:",
        explanations: [
            "Memproses pembelian tiket Anda.",
            "Mengirim konfirmasi pembayaran dan tiket.",
            "Menghubungi Anda untuk informasi terkait transaksi atau perubahan acara.",
            "Mengirimkan pemberitahuan dan pembaruan tentang layanan kami.",
            "Menganalisis penggunaan aplikasi kami untuk meningkatkan pengalaman pengguna.",
        ]
    },
    "sharing-information": {
        title: "Berbagi Informasi Anda",
        description: "Kami mungkin berbagi informasi pribadi Anda dengan pihak ketiga tertentu dalam hal yang diperlukan untuk:",
        explanations: [
            "Memproses pembayaran Anda.",
            "Memberikan tiket yang Anda beli.",
            "Mematuhi hukum dan peraturan yang berlaku.",
            "Melindungi hak, privasi, properti, atau keamanan kami atau pihak lain.",
        ]
    },
    "security-data":
    {
        title: "Keamanan Data",
        description: "Kami mengambil langkah-langkah keamanan yang wajar untuk melindungi informasi pribadi Anda dari akses yang tidak sah atau penggunaan yang salah."
    },
    "change-of-policy":
    {
        title: "Perubahan pada Kebijakan Privasi",
        description: "Kami dapat memperbarui kebijakan privasi ini dari waktu ke waktu. Setiap perubahan akan diberlakukan segera setelah diperbarui dalam aplikasi kami."
    },
    'contact-us': {
        title: "Hubungi Kami",
        description: "Jika Anda memiliki pertanyaan atau kekhawatiran tentang kebijakan privasi kami, silakan hubungi kami di etix@softorb.co.id."
    },
    'term-and-condition': {
        title: "Syarat dan Ketentuan",
        description: "Selamat datang di e-tix yang dikelola dan dimiliki oleh PT Softorb Technology Indonesia. Terima kasih telah mengunjungi e-tix, harap luangkan waktu untuk membaca Syarat & Ketentuan sebelum menggunakan Layanan Kami. Perjanjian ini merupakan kontrak yang mengikat, sehingga mohon dipastikan untuk membaca seluruh Ketentuan Layanan sebelum menggunakan Layanan Kami. Dimohon untuk tidak mengakses Layanan Kami jika tidak setuju dengan Syarat dan Ketentuan penggunaan e-tix.",
        contents: {
            'definition': {
                title: "Definisi",
                description: "Ungkapan yang ada dalam Ketentuan Pengguna merujuk pada arti dari sebagai berikut:",
                explanations: [
                    "“e-tix”, “kami”, dan “kita” merujuk pada PT Softorb Technology Indonesia, perseroan terbatas yang didirikan di Kompleks Mutiara Taman Palem Blok A11 No. 8 Cengkareng Timur. Jakarta Barat 11730 -Indonesia. Perseroan Terbatas ini didirikan atas dasar hukum yang berlaku di Indonesia.",
                    `“Pengguna”, “Anda” merujuk pada setiap pihak yang mengakses, memanfaatkan, dan/atau membuat akun di ${process.env.VUE_APP_WEB_MARKETPLACE_URL}.`,
                    `“Situs” merujuk pada ${process.env.VUE_APP_WEB_MARKETPLACE_URL} yang dimiliki dan dikelola oleh e-tix`,
                    `“Platform” merujuk pada sistem, software, data, dan media lain yang dikelola oleh e-tix`,
                    `“Pembeli” merujuk pada pengguna layanan kami, termasuk yang mengonsumsi informasi atau yang membeli tiket dari Seller di platform kami.`,
                    `“Seller” merujuk pada penyedia tiket, penyelenggara event, promotor, dan/atau panitia yang bekerjasama dan menggunakan platform kami baik untuk melakukan penjualan ataupun mengadakan pengiklanan atau promosi.`,
                    `“Service Fee” merujuk pada biaya yang kami terima atas produk atau jasa layanan kami.`,
                ]
            },
            'general-term-and-condition': {
                title: "Syarat dan Ketentuan Umum"
            }
        }
    }
}