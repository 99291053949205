const limitation = {
    maxOrderTicket: 20,
    product: {
        race: {
            contact: {
                full_name: 60,
                email: 320,
                phone_number: 12,
                address: 1000
            },
            participants: {
                first_name: 30,
                last_name: 30,
                identity_card: 16,
                bib_name: 8,
                medical_condition: 1000
            },
            emergency: {
                full_name: 60,
                phone_number: 12
            }
        }
    }
}

export default limitation