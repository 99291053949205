export default {
    title: "Pusat Bantuan",
    search: {
        placeholder: "Apa yang bisa kami bantu?",
        result: "Ditemukan {count} hasil pencarian untuk \"{keyword}\""
    },
    'select-role': {
        title: ["Pilih", "Role", "-mu"]
    },
    survey: {
        title: "Apakah artikel ini membantumu?",
        options: {
            helped: "Ya, terbantu",
            "not-helped": "Belum nih!",
        },
        'sub-option': {
            title: "Bantu Kami agar bisa melayanimu lebih baik",
            options: [
                "Panduan sulit dipahami",
                "Tidak menemukan yang dicari",
                "Lainnya"
            ],
            other: {
                placeholder: "Tuliskan penjelasanmu disini"
            }
        },
        accepted: "Tanggapanmu Telah Diterima"
    },
    'more-help': {
        title: "Masih Butuh Bantuan?",
        subtitle: "Silahkan hubungi kamu pada kontak di bawah ini."
    },
    button: {
        back: 'Kembali'
    }
}