export default {
    title: "Ways You Can Reach Out!",
    description: "Kami sangat senang mendengar darimu! Jika kamu memiliki pertanyaan,\
          masukan, atau ingin berbagi pengalaman menggunakan platform e-tix, tim\
          kami siap membantu kamu.Silakan hubungi kami melalui salah satu\
          metode di bawah ini: ",
    content: {
        title: "Kontak Kami",
        form: {
            name: {
                label: "Nama",
                placeholder: "Nama Kamu",
                rules: [
                    "Nama tidak boleh kosong"
                ]
            },
            email: {
                label: "Alamat Email",
                placeholder: "Alamat Email Kamu",
                rules: [
                    "Email tidak boleh kosong",
                    "Email belum tepat"
                ]
            },
            subject: {
                label: "Subjek Pesan",
                placeholder: "Subjek Pesan Kamu",
                rules: [
                    "Subjek tidak boleh kosong"
                ]
            },
            message: {
                label: "Pesan atau Pertanyaan",
                placeholder: "Tuliskan pesan atau pertanyaan Kamu di sini",
                rules: [
                    "Pesan tidak boleh kosongs"
                ]
            },
        },
        'send-email' : {
            success: "Pesan berhasil terkirim",
            "service-error": "Pesan gagal terkirim, service sedang bermasalah"
        }
    },
    button: {
        send: "Kirim"
    }
}