export default {
    carousel: {
        concert: {
            title: "Konser Musik Terkini",
            subtitle: "Dengarkan musik secara live bersama penyanyi/group bands terfavoritmu"
        },
        attraction: {
            title: "Tiket Atraksi",
            subtitle: "Temukan atraksi yang cocok untukmu"
        },
        upcoming: {
            title: "Upcoming Events",
            subtitle: "Event yang bisa kamu beli dan ikuti nantinya"
        },
        healing: {
            title: "Healing Sejenak",
            subtitle: "Bersantailah sejenak dengan mendatangi tempat hiburan"
        },
        race: {
            title: "Race",
            subtitle: "Olahraga menarik yang dapat kamu ikuti!"
        }
    },
    'info-1': {
        title: "Belum Yakin Beli Tiket di e-tix?",
        content: [
            {
                title: "Tiket Lengkap dan Beragam",
                subtitle:
                    "Kamu bisa menemukan beragam jenis tiket mulai dari konser, healing, taman hiburan hingga retail!",

            },
            {
                title: "Transaksi Aman dan Mudah",
                subtitle:
                    "Transaksi aman dan mudah dengan berbagai pilihan metode pembayaran",

            },
            {
                title: "Proses Cepat dan Bisa Dipercaya",
                subtitle:
                    "Kamu akan menerima e-ticket via email dalam hitungan detik setelah proses transaksimu berhasil",

            },
        ],
    },
    'info-2': {
        title: "Kamu Mau Buat Event?",
        content: "Yuk, bergabung dengan kami dan wujudkan eventmu, bisa juga tempat\
        hiburan loh! Rasakan pengalaman tidak terlupakan mulai dari tiket\
        dipasarkan hingga analisa penjualan yang akurat!"
    },
    'info-3': {
        title: 'Masih Ragu Untuk Kerja Sama Dengan Kami?',
        content: [
            "Pembayaran yang terintegrasi dengan sistem terpercaya",
            "Promosi ke banyak afiliasi kami",
            "Analisa penjualan",
            "Pencetakan dan pengiriman tiket fisik",
            "Penukaran tiket yang terintegrasi dengan akurat",
            "Pusat bantuan yang fast response",
        ],
    },
    newsletter: {
        title: "Daftar Newsletter Kami",
        subtitle: "Dapatkan berita, promo terbaru ataupun event terbaru dari kami",
        register: {
            success: "Terima kasih sudah mendaftar! Tunggu informasi terbaru dari kami.",
            'not-valid': "Format email yang kamu masukan kurang tepat",
            'service-error': "Mohon maaf, service sedang bermasalah. Mohon coba kembali beberapa saat lagi.",
            'already-register': "Anda sudah pernah mendaftar newsletter.",
            'user-not-found': 'Email {email} belum terdaftar pada sistem kami.'
        }
    },
    button: {
        'see-more': 'Lihat Lebih Banyak',
        "register-ticket": "Daftarkan Tiket",
        'register': "Daftar",
        'close': "Tutup"
    }
}
