export default {
    quotes: [
        "Beli beragam jenis tiket,",
        "cepat, mudah dan aman",
        "hanya di e-tix."
    ],
    info: [
        {
            title: "Tentang Kami",
            description: " e-tix adalah marketplace e-ticketing pertama di Indonesia yang\
            menjembatani pembeli tiket dan penjual tiket, di mana pembeli bisa\
            membeli beragam tiket berbeda dari penjual yang berbeda.e-tix\
            membantu penjual tiket tidak hanya saat tiket dibeli, tetapi hingga\
            diverifikasi."
        },
        {
            title: "Belum Yakin Beli Tiket di e-tix?",
            contents: [
                {
                    title: "Tiket Lengkap dan Beragam",
                    subtitle:
                        "Kamu bisa menemukan beragam jenis tiket mulai dari konser, healing, taman hiburan hingga retail!",
                },
                {
                    title: "Transaksi Aman dan Mudah",
                    subtitle:
                        "Transaksi aman dan mudah dengan berbagai pilihan metode pembayaran",
                },
                {
                    title: "Proses Cepat dan Bisa Dipercaya",
                    subtitle:
                        "Kamu akan menerima e-ticket via email dalam hitungan detik setelah proses transaksimu berhasil",
                }
            ]
        },
        {
            title: "Kamu Mau Buat Event?",
            description: "Yuk, bergabung dengan kami dan wujudkan eventmu, bisa juga tempat\
          hiburan loh! Rasakan pengalaman tidak terlupakan mulai dari tiket\
          dipasarkan hingga analisa penjualan yang akurat!"
        },
        {
            title: "Download app to enjoy more!",
            description: "e-tix memiliki layanan mulai dari pembelian tiket sampai tiket\
          tersebut dapat diverifikasi dan digunakan sebagai akses masuk.e-tix\
          juga menyediakan beragam jenis kebutuhan perjalanan, atraksi /\
        Hiburan, retail dan transportasi."
        }
    ],
    button: {
        "register-ticket": "Daftarkan Tiket"
    }
}